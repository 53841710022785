@import './variables';

.accordion-button {
  align-items: center;
  background-color: $bs-white;
  border: 0;
  border-radius: 0;
  color: $bs-gray-900;
  display: flex;
  font-size: $one-rem;
  overflow-anchor: none;
  padding: $one-rem 1.25rem;
  position: relative;
  text-align: left;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  width: 100%;

  &:not(.collapsed) {
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
    color: #0c63e4;

    &:after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
      transform: rotate(-180deg);
    }
  }

  &:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    content: '';
    flex-shrink: 0;
    height: 1.25rem;
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
    width: 1.25rem;
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
    z-index: 3;
  }
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: $bs-white;
  border: 1px solid rgba(0, 0, 0, 0.125);

  &:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

    .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px);
    }
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  &:last-of-type {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    .accordion-button.collapsed {
      border-bottom-left-radius: calc(0.25rem - 1px);
      border-bottom-right-radius: calc(0.25rem - 1px);
    }

    .accordion-collapse {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
}

.accordion-body {
  padding: $one-rem 1.25rem;
}

.accordion-flush {
  .accordion-collapse {
    border-width: 0;
  }

  .accordion-item {
    border-left: 0;
    border-radius: 0;
    border-right: 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    .accordion-button {
      border-radius: 0;
    }
  }
}
