@import 'components/variables';

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: Lato, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*,
:after,
:before {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  margin: 0;
  text-align: var(--bs-body-text-align);
}

hr {
  background-color: currentColor;
  border: 0;
  color: inherit;
  margin: $one-rem 0;
  opacity: 0.25;

  &:not([size]) {
    height: 1px;
  }
}

p {
  margin-bottom: $one-rem;
  margin-top: 0;
}

abbr {
  &[data-bs-original-title],
  &[title] {
    cursor: help;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: $one-rem;
}

ol,
ul {
  padding-left: 2rem;
}

dl,
ol,
ul {
  margin-bottom: $one-rem;
  margin-top: 0;
}

ol {
  ol,
  ul {
    margin-bottom: 0;
  }
}

ul {
  ol,
  ul {
    margin-bottom: 0;
  }
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 $one-rem;
}

b,
strong {
  font-weight: bolder;
}

.small,
small {
  font-size: 0.875em;
}

.mark,
mark {
  background-color: #fcf8e3;
  padding: 0.2em;
}

sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: $bs-blue;
  text-decoration: underline;

  &:hover {
    color: $bs-blue-dark;
  }

  &:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

code,
kbd,
pre,
samp {
  direction: ltr;
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  font-size: 0.875em;
  margin-bottom: $one-rem;
  margin-top: 0;
  overflow: auto;

  code {
    color: inherit;
    font-size: inherit;
    word-break: normal;
  }
}

code {
  word-wrap: break-word;
  color: $bs-blue-dark;
  font-size: 0.875em;
}

a > code {
  color: inherit;
}

kbd {
  background-color: $bs-gray-900;
  border-radius: 0.2rem;
  color: $bs-white;
  font-size: 0.875em;
  padding: 0.2rem 0.4rem;

  kbd {
    font-size: 1em;
    font-weight: 700;
    padding: 0;
  }
}

figure {
  margin: 0 0 $one-rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  caption-side: bottom;
}

caption {
  color: $bs-gray;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;

  &:disabled {
    opacity: 1;
  }
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  margin-bottom: 0.5rem;
  padding: 0;
  width: 100%;

  + * {
    clear: left;
  }
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}
