@import './variables';

.border {
  border: 1px solid $bs-gray-300 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid $bs-gray-300 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid $bs-gray-300 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid $bs-gray-300 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid $bs-gray-300 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: $bs-blue !important;
}

.border-secondary {
  border-color: $bs-gray !important;
}

.border-success {
  border-color: $bs-green !important;
}

.border-info {
  border-color: $bs-cyan !important;
}

.border-warning {
  border-color: $bs-yellow !important;
}

.border-danger {
  border-color: $bs-red !important;
}

.border-light {
  border-color: $bs-gray-100 !important;
}

.border-dark {
  border-color: $bs-gray-900 !important;
}

.border-white {
  border-color: $bs-white !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}
