@import './variables';

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  color: $bs-blue;
  display: block;
  padding: 0.5rem $one-rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  &:focus,
  &:hover {
    color: $bs-blue-dark;
  }

  &.disabled {
    color: $bs-gray;
    cursor: default;
    pointer-events: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-tabs {
  border-bottom: 1px solid $bs-gray-300;

  .nav-link {
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    margin-bottom: -1px;

    &:focus,
    &:hover {
      border-color: $bs-gray-200 $bs-gray-200 $bs-gray-300;
      isolation: isolate;
    }

    &.disabled {
      background-color: transparent;
      border-color: transparent;
      color: $bs-gray;
    }
  }

  .nav-item.show .nav-link,
  .nav-link.active {
    background-color: $bs-white;
    border-color: $bs-gray-300 $bs-gray-300 $bs-white;
    color: $bs-gray-700;
  }

  .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px;
  }
}

.nav-pills {
  .nav-link {
    background: none;
    border: 0;
    border-radius: 0.25rem;

    &.active {
      background-color: $bs-blue;
      color: $bs-white;
    }
  }

  .show > .nav-link {
    background-color: $bs-blue;
    color: $bs-white;
  }
}

.nav-fill {
  .nav-item,
  > .nav-link {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  .nav-item,
  > .nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > {
  .tab-pane {
    display: none;
  }

  .active {
    display: block;
  }
}

.navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  position: relative;

  > {
    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      align-items: center;
      display: flex;
      flex-wrap: inherit;
      justify-content: space-between;
    }
  }
}

.navbar-brand {
  font-size: 1.25rem;
  margin-right: $one-rem;
  padding-bottom: 0.3125rem;
  padding-top: 0.3125rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  .nav-link {
    padding-left: 0;
    padding-right: 0;
  }

  .dropdown-menu {
    position: static;
  }
}

.navbar-text {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}

.navbar-toggler {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  line-height: 1;
  padding: 0.25rem 0.75rem;
  transition: box-shadow 0.15s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem;
    outline: 0;
    text-decoration: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-toggler,
    .offcanvas-header {
      display: none;
    }

    .offcanvas {
      background-color: transparent;
      border-left: 0;
      border-right: 0;
      bottom: 0;
      flex-grow: 1;
      position: inherit;
      transform: none;
      transition: none;
      visibility: visible !important;
      z-index: 1000;
    }

    .offcanvas-bottom,
    .offcanvas-top {
      border-bottom: 0;
      border-top: 0;
      height: auto;
    }

    .offcanvas-body {
      display: flex;
      flex-grow: 0;
      overflow-y: visible;
      padding: 0;
    }
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-toggler,
    .offcanvas-header {
      display: none;
    }

    .offcanvas {
      background-color: transparent;
      border-left: 0;
      border-right: 0;
      bottom: 0;
      flex-grow: 1;
      position: inherit;
      transform: none;
      transition: none;
      visibility: visible !important;
      z-index: 1000;
    }

    .offcanvas-bottom,
    .offcanvas-top {
      border-bottom: 0;
      border-top: 0;
      height: auto;
    }

    .offcanvas-body {
      display: flex;
      flex-grow: 0;
      overflow-y: visible;
      padding: 0;
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-toggler,
    .offcanvas-header {
      display: none;
    }

    .offcanvas {
      background-color: transparent;
      border-left: 0;
      border-right: 0;
      bottom: 0;
      flex-grow: 1;
      position: inherit;
      transform: none;
      transition: none;
      visibility: visible !important;
      z-index: 1000;
    }

    .offcanvas-bottom,
    .offcanvas-top {
      border-bottom: 0;
      border-top: 0;
      height: auto;
    }

    .offcanvas-body {
      display: flex;
      flex-grow: 0;
      overflow-y: visible;
      padding: 0;
    }
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-toggler,
    .offcanvas-header {
      display: none;
    }

    .offcanvas {
      background-color: transparent;
      border-left: 0;
      border-right: 0;
      bottom: 0;
      flex-grow: 1;
      position: inherit;
      transform: none;
      transition: none;
      visibility: visible !important;
      z-index: 1000;
    }

    .offcanvas-bottom,
    .offcanvas-top {
      border-bottom: 0;
      border-top: 0;
      height: auto;
    }

    .offcanvas-body {
      display: flex;
      flex-grow: 0;
      overflow-y: visible;
      padding: 0;
    }
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    .navbar-nav-scroll {
      overflow: visible;
    }

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-toggler,
    .offcanvas-header {
      display: none;
    }

    .offcanvas {
      background-color: transparent;
      border-left: 0;
      border-right: 0;
      bottom: 0;
      flex-grow: 1;
      position: inherit;
      transform: none;
      transition: none;
      visibility: visible !important;
      z-index: 1000;
    }

    .offcanvas-bottom,
    .offcanvas-top {
      border-bottom: 0;
      border-top: 0;
      height: auto;
    }

    .offcanvas-body {
      display: flex;
      flex-grow: 0;
      overflow-y: visible;
      padding: 0;
    }
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;

  .navbar-nav {
    flex-direction: row;

    .dropdown-menu {
      position: absolute;
    }

    .nav-link {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-toggler,
  .offcanvas-header {
    display: none;
  }

  .offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    flex-grow: 1;
    position: inherit;
    transform: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
  }

  .offcanvas-bottom,
  .offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
  }

  .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}

.navbar-light {
  .navbar-brand {
    color: rgba(0, 0, 0, 0.9);

    &:focus,
    &:hover {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .navbar-nav {
    .nav-link {
      color: rgba(0, 0, 0, 0.55);

      &:focus,
      &:hover {
        color: rgba(0, 0, 0, 0.7);
      }

      &.disabled {
        color: rgba(0, 0, 0, 0.3);
      }

      &.active {
        color: rgba(0, 0, 0, 0.9);
      }
    }

    .show > .nav-link {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.55);
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0,0,0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .navbar-text {
    color: rgba(0, 0, 0, 0.55);

    a {
      color: rgba(0, 0, 0, 0.9);

      &:focus,
      &:hover {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
}

.navbar-dark {
  .navbar-brand {
    color: $bs-white;

    &:focus,
    &:hover {
      color: $bs-white;
    }
  }

  .navbar-nav {
    .nav-link {
      color: hsla(0, 0%, 100%, 0.55);

      &:focus,
      &:hover {
        color: hsla(0, 0%, 100%, 0.75);
      }

      &.disabled {
        color: hsla(0, 0%, 100%, 0.25);
      }

      &.active {
        color: $bs-white;
      }
    }

    .show > .nav-link {
      color: $bs-white;
    }
  }

  .navbar-toggler {
    border-color: hsla(0, 0%, 100%, 0.1);
    color: hsla(0, 0%, 100%, 0.55);
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .navbar-text {
    color: hsla(0, 0%, 100%, 0.55);

    a {
      color: $bs-white;

      &:focus,
      &:hover {
        color: $bs-white;
      }
    }
  }
}
