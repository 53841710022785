@import './variables';

.link-primary {
  color: $bs-blue;

  &:focus,
  &:hover {
    color: $bs-blue-dark;
  }
}

.link-secondary {
  color: $bs-gray;

  &:focus,
  &:hover {
    color: #565e64;
  }
}

.link-success {
  color: $bs-green;

  &:focus,
  &:hover {
    color: #146c43;
  }
}

.link-info {
  color: $bs-cyan;

  &:focus,
  &:hover {
    color: #3dd5f3;
  }
}

.link-warning {
  color: $bs-yellow;

  &:focus,
  &:hover {
    color: #ffcd39;
  }
}

.link-danger {
  color: $bs-red;

  &:focus,
  &:hover {
    color: #b02a37;
  }
}

.link-light {
  color: $bs-gray-100;

  &:focus,
  &:hover {
    color: #f9fafb;
  }
}

.link-dark {
  color: $bs-gray-900;

  &:focus,
  &:hover {
    color: #1a1e21;
  }
}
