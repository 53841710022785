@import './variables';

.toast {
  background-clip: padding-box;
  background-color: hsla(0, 0%, 100%, 0.85);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem $one-rem rgba(0, 0, 0, 0.15);
  font-size: 0.875rem;
  max-width: 100%;
  pointer-events: auto;
  width: 350px;

  &.showing {
    opacity: 0;
  }

  &:not(.show) {
    display: none;
  }
}

.toast-container {
  max-width: 100%;
  pointer-events: none;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;

  > :not(:last-child) {
    margin-bottom: 0.75rem;
  }
}

.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: hsla(0, 0%, 100%, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  color: $bs-gray;
  display: flex;
  padding: 0.5rem 0.75rem;

  .btn-close {
    margin-left: 0.75rem;
    margin-right: -0.375rem;
  }
}

.toast-body {
  word-wrap: break-word;
  padding: 0.75rem;
}
