@import './variables';
.carousel {
  position: relative;

  &.pointer-event {
    touch-action: pan-y;
  }
}

.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

.carousel-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  float: left;
  margin-right: -100%;
  position: relative;
  transition: transform 0.6s ease-in-out;
  width: 100%;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%);
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}

.carousel-fade {
  .carousel-item {
    opacity: 0;
    transform: none;
    transition-property: opacity;
  }

  .carousel-item-next.carousel-item-start,
  .carousel-item-prev.carousel-item-end,
  .carousel-item.active {
    opacity: 1;
    z-index: 1;
  }

  .active {
    &.carousel-item-end,
    &.carousel-item-start {
      opacity: 0;
      transition: opacity 0s 0.6s;
      z-index: 0;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active {
    &.carousel-item-end,
    &.carousel-item-start {
      transition: none;
    }
  }
}

.carousel-control-next,
.carousel-control-prev {
  align-items: center;
  background: none;
  border: 0;
  bottom: 0;
  color: $bs-white;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 0.15s ease;
  width: 15%;
  z-index: 1;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next {
  &:focus,
  &:hover {
    color: $bs-white;
    opacity: 0.9;
    outline: 0;
    text-decoration: none;
  }
}

.carousel-control-prev {
  &:focus,
  &:hover {
    color: $bs-white;
    opacity: 0.9;
    outline: 0;
    text-decoration: none;
  }

  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.carousel-control-prev-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  list-style: none;
  margin-bottom: $one-rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 2;

  [data-bs-target] {
    background-clip: padding-box;
    background-color: $bs-white;
    border: 0;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    box-sizing: content-box;
    cursor: pointer;
    flex: 0 1 auto;
    height: 3px;
    margin-left: 3px;
    margin-right: 3px;
    opacity: 0.5;
    padding: 0;
    text-indent: -999px;
    transition: opacity 0.6s ease;
    width: 30px;
  }

  .active {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-caption {
  bottom: 1.25rem;
  color: $bs-white;
  left: 15%;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  position: absolute;
  right: 15%;
  text-align: center;
}

.carousel-dark {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    filter: invert(1) grayscale(100);
  }

  .carousel-indicators [data-bs-target] {
    background-color: #000;
  }

  .carousel-caption {
    color: #000;
  }
}
