$bs-blue: #0d6efd;
$bs-blue-dark: #0a58ca;
$bs-indigo: #6610f2;
$bs-purple: #6f42c1;
$bs-pink: #d63384;
$bs-red: #dc3545;
$bs-orange: #fd7e14;
$bs-yellow: #ffc107;
$bs-green: #198754;
$bs-teal: #20c997;
$bs-cyan: #0dcaf0;
$bs-white: #fff;
$bs-gray: #6c757d;
$bs-gray-dark: #343a40;
$bs-gray-100: #f8f9fa;
$bs-gray-200: #e9ecef;
$bs-gray-300: #dee2e6;
$bs-gray-400: #ced4da;
$bs-gray-500: #adb5bd;
$bs-gray-600: #6c757d;
$bs-gray-700: #495057;
$bs-gray-800: #343a40;
$bs-gray-900: #212529;
$bs-primary: #0d6efd;
$bs-secondary: #6c757d;
$bs-success: #198754;
$bs-info: #0dcaf0;
$bs-warning: #ffc107;
$bs-danger: #dc3545;
$bs-light: #f8f9fa;
$bs-dark: #212529;
$bs-primary-rgb: 13, 110, 253;
$bs-secondary-rgb: 108, 117, 125;
$bs-success-rgb: 25, 135, 84;
$bs-info-rgb: 13, 202, 240;
$bs-warning-rgb: 255, 193, 7;
$bs-danger-rgb: 220, 53, 69;
$bs-light-rgb: 248, 249, 250;
$bs-dark-rgb: 33, 37, 41;
$bs-white-rgb: 255, 255, 255;
$bs-black-rgb: 0, 0, 0;
$bs-body-color-rgb: 33, 37, 41;
$bs-body-bg-rgb: 255, 255, 255;
$bs-body-bg-pink-rgb: 214, 51, 132;
$bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;
$bs-gradient: linear-gradient(
  180deg,
  hsla(0, 0%, 100%, 0.15),
  hsla(0, 0%, 100%, 0)
);
$bs-body-font-family: Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
  Segoe UI Symbol, Noto Color Emoji;
$one-rem: 1rem;
$bs-body-font-size: $one-rem;
$bs-body-font-weight: 400;
$bs-body-line-height: 1.5;
$bs-body-color: #212529;
$bs-body-bg: #fff;
