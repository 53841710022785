@import './variables';

.pagination {
  display: flex;
  list-style: none;
  padding-left: 0;
}

.page-link {
  background-color: $bs-white;
  border: 1px solid $bs-gray-300;
  color: $bs-blue;
  display: block;
  position: relative;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    border-color: $bs-gray-300;
    z-index: 2;
  }

  &:focus,
  &:hover {
    background-color: $bs-gray-200;
    color: $bs-blue-dark;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
    z-index: 3;
  }
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-item {
  &:not(:first-child) .page-link {
    margin-left: -1px;
  }

  &.active .page-link {
    background-color: $bs-blue;
    border-color: $bs-blue;
    color: $bs-white;
    z-index: 3;
  }

  &.disabled .page-link {
    background-color: $bs-white;
    border-color: $bs-gray-300;
    color: $bs-gray;
    pointer-events: none;
  }
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item {
  &:first-child .page-link {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }

  &:last-child .page-link {
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}

.pagination-lg {
  .page-link {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }

  .page-item {
    &:first-child .page-link {
      border-bottom-left-radius: 0.3rem;
      border-top-left-radius: 0.3rem;
    }

    &:last-child .page-link {
      border-bottom-right-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }
  }
}

.pagination-sm {
  .page-link {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
  }

  .page-item {
    &:first-child .page-link {
      border-bottom-left-radius: 0.2rem;
      border-top-left-radius: 0.2rem;
    }

    &:last-child .page-link {
      border-bottom-right-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
    }
  }
}
