@charset "UTF-8";

@import 'components/variables';
@import 'reset';

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  font-size: 1.25rem;
  margin-bottom: $one-rem;

  > :last-child {
    margin-bottom: 0;
  }
}

.blockquote-footer {
  color: $bs-gray;
  font-size: 0.875em;
  margin-bottom: $one-rem;
  margin-top: (-$one-rem);

  &:before {
    content: '— ';
  }
}

@import 'components/header';
@import 'components/display';
@import 'components/list';
@import 'components/img';
@import 'components/figure';
@import 'components/container';
@import 'components/table';
@import 'components/form';
@import 'components/button';
@import 'components/fade';
@import 'components/collapse';

@import 'components/dropdown';
@import 'components/button-group';
@import 'components/navbar';
@import 'components/card';
@import 'components/accordion';
@import 'components/breadcrumb';
@import 'components/pagination';

.badge {
  border-radius: 0.25rem;
  color: $bs-white;
  display: inline-block;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  padding: 0.35em 0.65em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;

  &:empty {
    display: none;
  }
}

.btn .badge {
  position: relative;
  top: -1px;
}

@import 'components/alert';
@import 'components/progress';
@import 'components/list-group';

.btn-close {
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50% / 1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  box-sizing: content-box;
  color: #000;
  height: 1em;
  opacity: 0.5;
  padding: 0.25em;
  width: 1em;

  &:hover {
    color: #000;
    opacity: 0.75;
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    opacity: 1;
    outline: 0;
  }

  &.disabled,
  &:disabled {
    opacity: 0.25;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

@import 'components/toast';
@import 'components/modal';
@import 'components/tooltip';
@import 'components/popover';
@import 'components/carousel';
@import 'components/spinner';
@import 'components/offcanvas';
@import 'components/placeholder';

.clearfix:after {
  clear: both;
  content: '';
  display: block;
}

@import 'components/link';
@import 'components/ratio';

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect($bs-black-rgb, 0) !important;
  border: 0 !important;
  height: 1px !important;
  margin: (-1px) !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.stretched-link:after {
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  align-self: stretch;
  background-color: currentColor;
  display: inline-block;
  min-height: 1em;
  opacity: 0.25;
  width: 1px;
}

@import 'components/opacity';
@import 'components/overflow';
@import 'components/shadow';
@import 'components/position';
@import 'components/border';
@import 'components/size';
@import 'components/gap';
@import 'components/justify';
@import 'components/align';
@import 'components/order';
@import 'components/spacing';
@import 'components/font';
@import 'components/text';
@import 'components/background';

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

@import 'components/rounded';
@import 'components/composition';

.features-icons {
  padding-bottom: 7rem;
  padding-top: 7rem;

  .features-icons-item {
    .features-icons-icon {
      height: 7rem;

      i {
        font-size: 4.5rem;
      }
    }
  }
}

.about-us {
  .about-us-text {
    padding: 3rem;
  }

  .about-us-img {
    background-size: cover;
    min-height: 30rem;
  }
}

@media (min-width: 768px) {
  .about-us .about-us-text {
    padding: 7rem;
  }
}

footer.footer {
  padding-bottom: 4rem;
  padding-top: 4rem;

  .mails a {
    text-decoration: none;
    color: $bs-dark;

    &:hover,
    &:focus {
      color: $bs-pink;
    }
  }

  .logos img {
    max-width: 150px !important;
  }
}
