@import './variables';

.offcanvas {
  background-clip: padding-box;
  background-color: $bs-white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  outline: 0;
  position: fixed;
  transition: transform 0.3s ease-in-out;
  visibility: hidden;
  z-index: 1045;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.5;
  }
}

.offcanvas-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $one-rem;

  .btn-close {
    margin-bottom: -0.5rem;
    margin-right: -0.5rem;
    margin-top: -0.5rem;
    padding: 0.5rem;
  }
}

.offcanvas-title {
  line-height: $bs-body-line-height;
  margin-bottom: 0;
}

.offcanvas-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: $one-rem;
}

.offcanvas-start {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  transform: translateX(-100%);
  width: 400px;
}

.offcanvas-end {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: 400px;
}

.offcanvas-top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  top: 0;
  transform: translateY(-100%);
}

.offcanvas-bottom,
.offcanvas-top {
  height: 30vh;
  left: 0;
  max-height: 100%;
  right: 0;
}

.offcanvas-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}
