@import './variables';

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

.spinner-border {
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  border: 0.25em solid;
  border-radius: 50%;
  border-right: 0.25em solid transparent;
  display: inline-block;
  height: 2rem;
  vertical-align: -0.125em;
  width: 2rem;
}

.spinner-border-sm {
  border-width: 0.2em;
  height: $one-rem;
  width: $one-rem;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  opacity: 0;
  vertical-align: -0.125em;
  width: 2rem;
}

.spinner-grow-sm {
  height: $one-rem;
  width: $one-rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
