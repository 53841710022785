@import './variables';

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  font-size: inherit;
  line-height: $bs-body-line-height;
  margin-bottom: 0;
  padding-bottom: calc(0.75rem + 1px);
  padding-top: calc(0.75rem + 1px);
}

.col-form-label-lg {
  font-size: 1.25rem;
  padding-bottom: calc($one-rem + 1px);
  padding-top: calc($one-rem + 1px);
}

.col-form-label-sm {
  font-size: 0.875rem;
  padding-bottom: calc(0.5rem + 1px);
  padding-top: calc(0.5rem + 1px);
}

.form-text {
  color: $bs-gray;
  font-size: 0.875em;
  margin-top: 0.25rem;
}

.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: $bs-white;
  border: 1px solid $bs-gray-400;
  border-radius: 0.25rem;
  color: $bs-gray-900;
  display: block;
  font-size: $one-rem;
  font-weight: $bs-body-font-weight;
  line-height: $bs-body-line-height;
  padding: 0.75rem $one-rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;

  &[type='file'] {
    overflow: hidden;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &:focus {
    background-color: $bs-white;
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    color: $bs-gray-900;
    outline: 0;
  }

  &::-webkit-date-and-time-value {
    height: 1.5em;
  }

  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::placeholder {
    color: $bs-gray;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: $bs-gray-200;
    opacity: 1;
  }

  &::file-selector-button {
    -webkit-margin-end: $one-rem;
    background-color: $bs-gray-200;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: 1px;
    border-radius: 0;
    color: $bs-gray-900;
    margin: -0.75rem -$one-rem;
    margin-inline-end: $one-rem;
    padding: 0.75rem $one-rem;
    pointer-events: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3;
  }

  &::-webkit-file-upload-button {
    -webkit-margin-end: $one-rem;
    background-color: $bs-gray-200;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: 1px;
    border-radius: 0;
    color: $bs-gray-900;
    margin: -0.75rem -$one-rem;
    margin-inline-end: $one-rem;
    padding: 0.75rem $one-rem;
    pointer-events: none;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    &::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none;
    }

    &::file-selector-button {
      transition: none;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  color: $bs-gray-900;
  display: block;
  line-height: $bs-body-line-height;
  margin-bottom: 0;
  padding: 0.75rem 0;
  width: 100%;

  &.form-control-lg,
  &.form-control-sm {
    padding-left: 0;
    padding-right: 0;
  }
}

.form-control-sm {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  min-height: calc(1.5em + $one-rem + 2px);
  padding: 0.5rem 0.75rem;

  &::file-selector-button,
  &::-webkit-file-upload-button {
    -webkit-margin-end: 0.75rem;
    margin: -0.5rem -0.75rem;
    margin-inline-end: 0.75rem;
    padding: 0.5rem 0.75rem;
  }
}

.form-control-lg {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  min-height: calc(1.5em + 2rem + 2px);
  padding: $one-rem 1.25rem;

  &::file-selector-button,
  &::-webkit-file-upload-button {
    -webkit-margin-end: 1.25rem;
    margin: -$one-rem -1.25rem;
    margin-inline-end: 1.25rem;
    padding: $one-rem 1.25rem;
  }
}

textarea {
  &.form-control {
    min-height: calc(1.5em + 1.5rem + 2px);
  }

  &.form-control-sm {
    min-height: calc(1.5em + $one-rem + 2px);
  }

  &.form-control-lg {
    min-height: calc(1.5em + 2rem + 2px);
  }
}

.form-control-color {
  height: auto;
  padding: 0.75rem;
  width: 3rem;

  &:not(:disabled):not([readonly]) {
    cursor: pointer;
  }

  &::-moz-color-swatch,
  &::-webkit-color-swatch {
    border-radius: 0.25rem;
    height: 1.5em;
  }
}

.form-select {
  -moz-padding-start: calc($one-rem - 3px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: $bs-white;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  background-position: right $one-rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid $bs-gray-400;
  border-radius: 0.25rem;
  color: $bs-gray-900;
  display: block;
  font-size: $one-rem;
  font-weight: $bs-body-font-weight;
  line-height: $bs-body-line-height;
  padding: 0.75rem 3rem 0.75rem $one-rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;

  &:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
  }

  &[multiple],
  &[size]:not([size='1']) {
    background-image: none;
    padding-right: $one-rem;
  }

  &:disabled {
    background-color: $bs-gray-200;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $bs-gray-900;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select-sm {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-top: 0.5rem;
}

.form-select-lg {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  padding-bottom: $one-rem;
  padding-left: 1.25rem;
  padding-top: $one-rem;
}

.form-check {
  display: block;
  margin-bottom: 0.125rem;
  min-height: 1.5rem;
  padding-left: 1.5em;

  .form-check-input {
    float: left;
    margin-left: -1.5em;
  }
}

.form-check-input {
  -webkit-print-color-adjust: exact;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: $bs-white;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  color-adjust: exact;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  width: 1em;

  &[type='checkbox'] {
    border-radius: 0.25em;
  }

  &[type='radio'] {
    border-radius: 50%;
  }

  &:active {
    filter: brightness(90%);
  }

  &:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
  }

  &:checked {
    background-color: $bs-blue;
    border-color: $bs-blue;

    &[type='checkbox'] {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
    }

    &[type='radio'] {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
    }
  }

  &[type='checkbox']:indeterminate {
    background-color: $bs-blue;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
    border-color: $bs-blue;
  }

  &:disabled {
    filter: none;
    opacity: 0.5;
    pointer-events: none;

    ~ .form-check-label {
      opacity: 0.5;
    }
  }

  &[disabled] ~ .form-check-label {
    opacity: 0.5;
  }
}

.form-switch {
  padding-left: 2.5em;

  .form-check-input {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0,0,0, 0.25)'/%3E%3C/svg%3E");
    background-position: 0;
    border-radius: 2em;
    margin-left: -2.5em;
    transition: background-position 0.15s ease-in-out;
    width: 2em;

    &:focus {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2386b7fe'/%3E%3C/svg%3E");
    }

    &:checked {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
      background-position: 100%;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: $one-rem;
}

.btn-check {
  clip: rect($bs-black-rgb, 0);
  pointer-events: none;
  position: absolute;

  &:disabled + .btn,
  &[disabled] + .btn {
    filter: none;
    opacity: 0.65;
    pointer-events: none;
  }
}

.form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  height: 1.5rem;
  padding: 0;
  width: 100%;

  &:focus {
    outline: 0;

    &::-webkit-slider-thumb,
    &::-moz-range-thumb {
      box-shadow: 0 0 0 1px $bs-white, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: $bs-blue;
    border: 0;
    border-radius: $one-rem;
    height: $one-rem;
    margin-top: -0.25rem;
    -webkit-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: $one-rem;

    &:active {
      background-color: #b6d4fe;
    }
  }

  &::-webkit-slider-runnable-track {
    background-color: $bs-gray-300;
    border-color: transparent;
    border-radius: $one-rem;
    color: transparent;
    cursor: pointer;
    height: 0.5rem;
    width: 100%;
  }

  &::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    background-color: $bs-blue;
    border: 0;
    border-radius: $one-rem;
    height: $one-rem;
    -moz-transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: $one-rem;

    &:active {
      background-color: #b6d4fe;
    }
  }

  &::-moz-range-track {
    background-color: $bs-gray-300;
    border-color: transparent;
    border-radius: $one-rem;
    color: transparent;
    cursor: pointer;
    height: 0.5rem;
    width: 100%;
  }

  &:disabled {
    pointer-events: none;

    &::-webkit-slider-thumb,
    &::-moz-range-thumb {
      background-color: $bs-gray-500;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-floating {
  position: relative;

  > {
    .form-control,
    .form-select {
      height: calc(3.5rem + 2px);
      line-height: 1.25;
    }

    label {
      border: 1px solid transparent;
      height: 100%;
      left: 0;
      padding: $one-rem;
      pointer-events: none;
      position: absolute;
      top: 0;
      transform-origin: 0 0;
      transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    }

    .form-control {
      padding: $one-rem;

      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        color: transparent;
      }

      &:not(:-moz-placeholder-shown),
      &:not(:-ms-input-placeholder),
      &:focus,
      &:not(:placeholder-shown),
      &:-webkit-autofill {
        padding-bottom: 0.625rem;
        padding-top: 1.625rem;
      }
    }

    .form-select {
      padding-bottom: 0.625rem;
      padding-top: 1.625rem;
    }

    .form-control {
      &:not(:-moz-placeholder-shown) ~ label,
      &:not(:-ms-input-placeholder) ~ label,
      &:focus ~ label,
      &:not(:placeholder-shown) ~ label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      }
    }

    .form-select ~ label,
    .form-control:-webkit-autofill ~ label {
      opacity: 0.65;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;

  > {
    .form-control,
    .form-select {
      flex: 1 1 auto;
      min-width: 0;
      position: relative;
      width: 1%;
    }

    .form-control:focus,
    .form-select:focus {
      z-index: 3;
    }
  }

  .btn {
    position: relative;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }
}

.input-group-text {
  align-items: center;
  background-color: $bs-gray-200;
  border: 1px solid $bs-gray-400;
  border-radius: 0.25rem;
  color: $bs-gray-900;
  display: flex;
  font-size: $one-rem;
  font-weight: $bs-body-font-weight;
  line-height: $bs-body-line-height;
  padding: 0.75rem $one-rem;
  text-align: center;
  white-space: nowrap;
}

.input-group-lg > {
  .btn,
  .form-control,
  .form-select,
  .input-group-text {
    border-radius: 0.3rem;
    font-size: 1.25rem;
    padding: $one-rem 1.25rem;
  }
}

.input-group-sm > {
  .btn,
  .form-control,
  .form-select,
  .input-group-text {
    border-radius: 0.2rem;
    font-size: 0.875rem;
    padding: 0.5rem 0.75rem;
  }
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group {
  &.has-validation > {
    .dropdown-toggle:nth-last-child(n + 4),
    :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:not(.has-validation) > {
    .dropdown-toggle:nth-last-child(n + 3),
    :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }
}

.valid-feedback {
  color: $bs-green;
  display: none;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}

.valid-tooltip {
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
  color: $bs-white;
  display: none;
  font-size: 0.875rem;
  margin-top: 0.1;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.is-valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.was-validated :valid ~ {
  .valid-feedback,
  .valid-tooltip {
    display: block;
  }
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right calc(0.375em + 0.375rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
  border-color: $bs-green;
  padding-right: calc(1.5em + 1.5rem);
}

.form-control.is-valid:focus {
  border-color: $bs-green;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated {
  .form-control:valid:focus {
    border-color: $bs-green;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
  }

  textarea.form-control:valid {
    background-position: top calc(0.375em + 0.375rem) right
      calc(0.375em + 0.375rem);
    padding-right: calc(1.5em + 1.5rem);
  }
}

textarea.form-control.is-valid {
  background-position: top calc(0.375em + 0.375rem) right
    calc(0.375em + 0.375rem);
  padding-right: calc(1.5em + 1.5rem);
}

.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: $bs-green;
}

.form-select.is-valid:not([multiple]) {
  &:not([size]),
  &[size='1'] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E"),
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-position: right $one-rem center, center right 3rem;
    background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
    padding-right: 5.5rem;
  }
}

.was-validated .form-select:valid:not([multiple]) {
  &:not([size]),
  &[size='1'] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E"),
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-position: right $one-rem center, center right 3rem;
    background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
    padding-right: 5.5rem;
  }
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: $bs-green;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: $bs-green;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: $bs-green;
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: $bs-green;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.input-group {
  .form-control.is-valid,
  .form-select.is-valid {
    z-index: 1;
  }
}

.was-validated .input-group {
  .form-control:valid,
  .form-select:valid {
    z-index: 1;
  }
}

.input-group {
  .form-control.is-valid:focus,
  .form-select.is-valid:focus {
    z-index: 3;
  }
}

.was-validated .input-group {
  .form-control:valid:focus,
  .form-select:valid:focus {
    z-index: 3;
  }
}

.invalid-feedback {
  color: $bs-red;
  display: none;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}

.invalid-tooltip {
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
  color: $bs-white;
  display: none;
  font-size: 0.875rem;
  margin-top: 0.1;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.is-invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.was-validated :invalid ~ {
  .invalid-feedback,
  .invalid-tooltip {
    display: block;
  }
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
  background-position: right calc(0.375em + 0.375rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
  border-color: $bs-red;
  padding-right: calc(1.5em + 1.5rem);
}

.form-control.is-invalid:focus {
  border-color: $bs-red;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated {
  .form-control:invalid:focus {
    border-color: $bs-red;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }

  textarea.form-control:invalid {
    background-position: top calc(0.375em + 0.375rem) right
      calc(0.375em + 0.375rem);
    padding-right: calc(1.5em + 1.5rem);
  }
}

textarea.form-control.is-invalid {
  background-position: top calc(0.375em + 0.375rem) right
    calc(0.375em + 0.375rem);
  padding-right: calc(1.5em + 1.5rem);
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: $bs-red;
}

.form-select.is-invalid:not([multiple]) {
  &:not([size]),
  &[size='1'] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E"),
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-position: right $one-rem center, center right 3rem;
    background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
    padding-right: 5.5rem;
  }
}

.was-validated .form-select:invalid:not([multiple]) {
  &:not([size]),
  &[size='1'] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E"),
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-position: right $one-rem center, center right 3rem;
    background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
    padding-right: 5.5rem;
  }
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: $bs-red;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: $bs-red;
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: $bs-red;
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: $bs-red;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.input-group {
  .form-control.is-invalid,
  .form-select.is-invalid {
    z-index: 2;
  }
}

.was-validated .input-group {
  .form-control:invalid,
  .form-select:invalid {
    z-index: 2;
  }
}

.input-group {
  .form-control.is-invalid:focus,
  .form-select.is-invalid:focus {
    z-index: 3;
  }
}

.was-validated .input-group {
  .form-control:invalid:focus,
  .form-select:invalid:focus {
    z-index: 3;
  }
}

.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: $bs-gray-900;
  cursor: pointer;
  display: inline-block;
  font-size: $one-rem;
  font-weight: $bs-body-font-weight;
  line-height: $bs-body-line-height;
  padding: 0.75rem $one-rem;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;

  &:hover {
    color: $bs-gray-900;
  }
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn-check:focus + .btn {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  outline: 0;
}

.btn {
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
  }

  &.disabled,
  &:disabled {
    opacity: 0.65;
    pointer-events: none;
  }
}

fieldset:disabled .btn {
  opacity: 0.65;
  pointer-events: none;
}
