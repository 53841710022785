@import 'variables';

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: $bs-gray-900;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: $bs-gray-900;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: $bs-gray-900;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  border-color: $bs-gray-300;
  color: $bs-gray-900;
  margin-bottom: $one-rem;
  vertical-align: top;
  width: 100%;

  > {
    :not(caption) > * > * {
      background-color: var(--bs-table-bg);
      border-bottom-width: 1px;
      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
      padding: 0.5rem;
    }

    tbody {
      vertical-align: inherit;
    }

    thead {
      vertical-align: bottom;
    }

    :not(:first-child) {
      border-top: 2px solid;
    }
  }
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;

  > * {
    border-width: 0 1px;
  }
}

.table-borderless > {
  :not(caption) > * > * {
    border-bottom-width: 0;
  }

  :not(:first-child) {
    border-top-width: 0;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  border-color: #bacbe6;
  color: #000;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  border-color: #cbccce;
  color: #000;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  border-color: #bcd0c7;
  color: #000;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  border-color: #badce3;
  color: #000;
}

.table-warning {
  --bs-table-bg: $bs-white;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  border-color: #e6dbb9;
  color: #000;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  border-color: #dfc2c4;
  color: #000;
}

.table-light {
  --bs-table-bg: $bs-gray-100;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  border-color: #dfe0e1;
  color: #000;
}

.table-dark {
  --bs-table-bg: $bs-gray-900;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: $bs-white;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: $bs-white;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: $bs-white;
  border-color: #373b3e;
  color: $bs-white;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
