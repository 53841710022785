.btn-group {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}

.btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;

  > .btn {
    flex: 1 1 auto;
    position: relative;
  }
}

.btn-group > .btn {
  flex: 1 1 auto;
  position: relative;
}

.btn-group-vertical > {
  .btn-check {
    &:checked + .btn,
    &:focus + .btn {
      z-index: 1;
    }
  }

  .btn {
    &.active,
    &:active,
    &:focus,
    &:hover {
      z-index: 1;
    }
  }
}

.btn-group > {
  .btn-check {
    &:checked + .btn,
    &:focus + .btn {
      z-index: 1;
    }
  }

  .btn {
    &.active,
    &:active,
    &:focus,
    &:hover {
      z-index: 1;
    }
  }
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .input-group {
    width: auto;
  }
}

.btn-group > {
  .btn-group:not(:first-child),
  .btn:not(:first-child) {
    margin-left: -1px;
  }

  .btn-group:not(:last-child) > .btn,
  .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .btn-group:not(:first-child) > .btn,
  .btn:nth-child(n + 3),
  :not(.btn-check) + .btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  &:after {
    margin-left: 0;
  }
}

.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  > {
    .btn {
      width: 100%;
    }

    .btn-group {
      width: 100%;

      &:not(:first-child) {
        margin-top: -1px;
      }
    }

    .btn:not(:first-child) {
      margin-top: -1px;
    }

    .btn-group:not(:last-child) > .btn,
    .btn:not(:last-child):not(.dropdown-toggle) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .btn-group:not(:first-child) > .btn,
    .btn ~ .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
