@import './variables';

.btn-pink {
  background-color: $bs-pink;
  border-color: $bs-pink;
  color: $bs-white;
}

.btn-pink {
  &:focus,
  &:hover {
    background-color: $bs-red;
    border-color: $bs-red;
    color: $bs-white;
  }
}

.btn-primary {
  background-color: $bs-blue;
  border-color: $bs-blue;
  color: $bs-white;
}

.btn-check:focus + .btn-primary {
  background-color: #0b5ed7;
  border-color: $bs-blue-dark;
  color: $bs-white;
}

.btn-primary {
  &:focus,
  &:hover {
    background-color: #0b5ed7;
    border-color: $bs-blue-dark;
    color: $bs-white;
  }
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-check {
  &:active + .btn-primary,
  &:checked + .btn-primary {
    background-color: $bs-blue-dark;
    border-color: #0a53be;
    color: $bs-white;
  }
}

.btn-primary {
  &.active,
  &:active {
    background-color: $bs-blue-dark;
    border-color: #0a53be;
    color: $bs-white;
  }
}

.show > .btn-primary.dropdown-toggle {
  background-color: $bs-blue-dark;
  border-color: #0a53be;
  color: $bs-white;
}

.btn-check {
  &:active + .btn-primary:focus,
  &:checked + .btn-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
  }
}

.btn-primary {
  &.active:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
  }
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-primary {
  &.disabled,
  &:disabled {
    background-color: $bs-blue;
    border-color: $bs-blue;
    color: $bs-white;
  }
}

.btn-secondary {
  background-color: $bs-gray;
  border-color: $bs-gray;
  color: $bs-white;
}

.btn-check:focus + .btn-secondary {
  background-color: #5c636a;
  border-color: #565e64;
  color: $bs-white;
}

.btn-secondary {
  &:focus,
  &:hover {
    background-color: #5c636a;
    border-color: #565e64;
    color: $bs-white;
  }
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.25rem hsla(208, 6%, 54%, 0.5);
}

.btn-check {
  &:active + .btn-secondary,
  &:checked + .btn-secondary {
    background-color: #565e64;
    border-color: #51585e;
    color: $bs-white;
  }
}

.btn-secondary {
  &.active,
  &:active {
    background-color: #565e64;
    border-color: #51585e;
    color: $bs-white;
  }
}

.show > .btn-secondary.dropdown-toggle {
  background-color: #565e64;
  border-color: #51585e;
  color: $bs-white;
}

.btn-check {
  &:active + .btn-secondary:focus,
  &:checked + .btn-secondary:focus {
    box-shadow: 0 0 0 0.25rem hsla(208, 6%, 54%, 0.5);
  }
}

.btn-secondary {
  &.active:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem hsla(208, 6%, 54%, 0.5);
  }
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem hsla(208, 6%, 54%, 0.5);
}

.btn-secondary {
  &.disabled,
  &:disabled {
    background-color: $bs-gray;
    border-color: $bs-gray;
    color: $bs-white;
  }
}

.btn-success {
  background-color: $bs-green;
  border-color: $bs-green;
  color: $bs-white;
}

.btn-check:focus + .btn-success {
  background-color: #157347;
  border-color: #146c43;
  color: $bs-white;
}

.btn-success {
  &:focus,
  &:hover {
    background-color: #157347;
    border-color: #146c43;
    color: $bs-white;
  }
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-check {
  &:active + .btn-success,
  &:checked + .btn-success {
    background-color: #146c43;
    border-color: #13653f;
    color: $bs-white;
  }
}

.btn-success {
  &.active,
  &:active {
    background-color: #146c43;
    border-color: #13653f;
    color: $bs-white;
  }
}

.show > .btn-success.dropdown-toggle {
  background-color: #146c43;
  border-color: #13653f;
  color: $bs-white;
}

.btn-check {
  &:active + .btn-success:focus,
  &:checked + .btn-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
  }
}

.btn-success {
  &.active:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
  }
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-success {
  &.disabled,
  &:disabled {
    background-color: $bs-green;
    border-color: $bs-green;
    color: $bs-white;
  }
}

.btn-info {
  background-color: $bs-cyan;
  border-color: $bs-cyan;
  color: #000;
}

.btn-check:focus + .btn-info {
  background-color: #31d2f2;
  border-color: #25cff2;
  color: #000;
}

.btn-info {
  &:focus,
  &:hover {
    background-color: #31d2f2;
    border-color: #25cff2;
    color: #000;
  }
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check {
  &:active + .btn-info,
  &:checked + .btn-info {
    background-color: #3dd5f3;
    border-color: #25cff2;
    color: #000;
  }
}

.btn-info {
  &.active,
  &:active {
    background-color: #3dd5f3;
    border-color: #25cff2;
    color: #000;
  }
}

.show > .btn-info.dropdown-toggle {
  background-color: #3dd5f3;
  border-color: #25cff2;
  color: #000;
}

.btn-check {
  &:active + .btn-info:focus,
  &:checked + .btn-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
  }
}

.btn-info {
  &.active:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
  }
}

.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info {
  &.disabled,
  &:disabled {
    background-color: $bs-cyan;
    border-color: $bs-cyan;
    color: #000;
  }
}

.btn-warning {
  background-color: $bs-yellow;
  border-color: $bs-yellow;
  color: #000;
}

.btn-check:focus + .btn-warning {
  background-color: #ffca2c;
  border-color: #ffc720;
  color: #000;
}

.btn-warning {
  &:focus,
  &:hover {
    background-color: #ffca2c;
    border-color: #ffc720;
    color: #000;
  }
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-check {
  &:active + .btn-warning,
  &:checked + .btn-warning {
    background-color: #ffcd39;
    border-color: #ffc720;
    color: #000;
  }
}

.btn-warning {
  &.active,
  &:active {
    background-color: #ffcd39;
    border-color: #ffc720;
    color: #000;
  }
}

.show > .btn-warning.dropdown-toggle {
  background-color: #ffcd39;
  border-color: #ffc720;
  color: #000;
}

.btn-check {
  &:active + .btn-warning:focus,
  &:checked + .btn-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
  }
}

.btn-warning {
  &.active:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
  }
}

.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-warning {
  &.disabled,
  &:disabled {
    background-color: $bs-yellow;
    border-color: $bs-yellow;
    color: #000;
  }
}

.btn-danger {
  background-color: $bs-red;
  border-color: $bs-red;
  color: $bs-white;
}

.btn-check:focus + .btn-danger {
  background-color: #bb2d3b;
  border-color: #b02a37;
  color: $bs-white;
}

.btn-danger {
  &:focus,
  &:hover {
    background-color: #bb2d3b;
    border-color: #b02a37;
    color: $bs-white;
  }
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-check {
  &:active + .btn-danger,
  &:checked + .btn-danger {
    background-color: #b02a37;
    border-color: #a52834;
    color: $bs-white;
  }
}

.btn-danger {
  &.active,
  &:active {
    background-color: #b02a37;
    border-color: #a52834;
    color: $bs-white;
  }
}

.show > .btn-danger.dropdown-toggle {
  background-color: #b02a37;
  border-color: #a52834;
  color: $bs-white;
}

.btn-check {
  &:active + .btn-danger:focus,
  &:checked + .btn-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
  }
}

.btn-danger {
  &.active:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
  }
}

.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-danger {
  &.disabled,
  &:disabled {
    background-color: $bs-red;
    border-color: $bs-red;
    color: $bs-white;
  }
}

.btn-light {
  background-color: $bs-gray-100;
  border-color: $bs-gray-100;
  color: #000;
}

.btn-check:focus + .btn-light {
  background-color: #f9fafb;
  border-color: #f9fafb;
  color: #000;
}

.btn-light {
  &:focus,
  &:hover {
    background-color: #f9fafb;
    border-color: #f9fafb;
    color: #000;
  }
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  box-shadow: 0 0 0 0.25rem hsla(210, 2%, 83%, 0.5);
}

.btn-check {
  &:active + .btn-light,
  &:checked + .btn-light {
    background-color: #f9fafb;
    border-color: #f9fafb;
    color: #000;
  }
}

.btn-light {
  &.active,
  &:active {
    background-color: #f9fafb;
    border-color: #f9fafb;
    color: #000;
  }
}

.show > .btn-light.dropdown-toggle {
  background-color: #f9fafb;
  border-color: #f9fafb;
  color: #000;
}

.btn-check {
  &:active + .btn-light:focus,
  &:checked + .btn-light:focus {
    box-shadow: 0 0 0 0.25rem hsla(210, 2%, 83%, 0.5);
  }
}

.btn-light {
  &.active:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem hsla(210, 2%, 83%, 0.5);
  }
}

.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem hsla(210, 2%, 83%, 0.5);
}

.btn-light {
  &.disabled,
  &:disabled {
    background-color: $bs-gray-100;
    border-color: $bs-gray-100;
    color: #000;
  }
}

.btn-dark {
  background-color: $bs-gray-900;
  border-color: $bs-gray-900;
  color: $bs-white;
}

.btn-check:focus + .btn-dark {
  background-color: #1c1f23;
  border-color: #1a1e21;
  color: $bs-white;
}

.btn-dark {
  &:focus,
  &:hover {
    background-color: #1c1f23;
    border-color: #1a1e21;
    color: $bs-white;
  }
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-check {
  &:active + .btn-dark,
  &:checked + .btn-dark {
    background-color: #1a1e21;
    border-color: #191c1f;
    color: $bs-white;
  }
}

.btn-dark {
  &.active,
  &:active {
    background-color: #1a1e21;
    border-color: #191c1f;
    color: $bs-white;
  }
}

.show > .btn-dark.dropdown-toggle {
  background-color: #1a1e21;
  border-color: #191c1f;
  color: $bs-white;
}

.btn-check {
  &:active + .btn-dark:focus,
  &:checked + .btn-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
  }
}

.btn-dark {
  &.active:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
  }
}

.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-dark {
  &.disabled,
  &:disabled {
    background-color: $bs-gray-900;
    border-color: $bs-gray-900;
    color: $bs-white;
  }
}

.btn-outline-primary {
  border-color: $bs-blue;
  color: $bs-blue;

  &:hover {
    background-color: $bs-blue;
    border-color: $bs-blue;
    color: $bs-white;
  }
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-check {
  &:active + .btn-outline-primary,
  &:checked + .btn-outline-primary {
    background-color: $bs-blue;
    border-color: $bs-blue;
    color: $bs-white;
  }
}

.btn-outline-primary {
  &.active,
  &.dropdown-toggle.show,
  &:active {
    background-color: $bs-blue;
    border-color: $bs-blue;
    color: $bs-white;
  }
}

.btn-check {
  &:active + .btn-outline-primary:focus,
  &:checked + .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
  }
}

.btn-outline-primary {
  &.active:focus,
  &.dropdown-toggle.show:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $bs-blue;
  }
}

.btn-outline-secondary {
  border-color: $bs-gray;
  color: $bs-gray;

  &:hover {
    background-color: $bs-gray;
    border-color: $bs-gray;
    color: $bs-white;
  }
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem hsla(208, 7%, 46%, 0.5);
}

.btn-check {
  &:active + .btn-outline-secondary,
  &:checked + .btn-outline-secondary {
    background-color: $bs-gray;
    border-color: $bs-gray;
    color: $bs-white;
  }
}

.btn-outline-secondary {
  &.active,
  &.dropdown-toggle.show,
  &:active {
    background-color: $bs-gray;
    border-color: $bs-gray;
    color: $bs-white;
  }
}

.btn-check {
  &:active + .btn-outline-secondary:focus,
  &:checked + .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem hsla(208, 7%, 46%, 0.5);
  }
}

.btn-outline-secondary {
  &.active:focus,
  &.dropdown-toggle.show:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem hsla(208, 7%, 46%, 0.5);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $bs-gray;
  }
}

.btn-outline-success {
  border-color: $bs-green;
  color: $bs-green;

  &:hover {
    background-color: $bs-green;
    border-color: $bs-green;
    color: $bs-white;
  }
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-check {
  &:active + .btn-outline-success,
  &:checked + .btn-outline-success {
    background-color: $bs-green;
    border-color: $bs-green;
    color: $bs-white;
  }
}

.btn-outline-success {
  &.active,
  &.dropdown-toggle.show,
  &:active {
    background-color: $bs-green;
    border-color: $bs-green;
    color: $bs-white;
  }
}

.btn-check {
  &:active + .btn-outline-success:focus,
  &:checked + .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
  }
}

.btn-outline-success {
  &.active:focus,
  &.dropdown-toggle.show:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $bs-green;
  }
}

.btn-outline-info {
  border-color: $bs-cyan;
  color: $bs-cyan;

  &:hover {
    background-color: $bs-cyan;
    border-color: $bs-cyan;
    color: #000;
  }
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check {
  &:active + .btn-outline-info,
  &:checked + .btn-outline-info {
    background-color: $bs-cyan;
    border-color: $bs-cyan;
    color: #000;
  }
}

.btn-outline-info {
  &.active,
  &.dropdown-toggle.show,
  &:active {
    background-color: $bs-cyan;
    border-color: $bs-cyan;
    color: #000;
  }
}

.btn-check {
  &:active + .btn-outline-info:focus,
  &:checked + .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
  }
}

.btn-outline-info {
  &.active:focus,
  &.dropdown-toggle.show:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $bs-cyan;
  }
}

.btn-outline-warning {
  border-color: $bs-yellow;
  color: $bs-yellow;

  &:hover {
    background-color: $bs-yellow;
    border-color: $bs-yellow;
    color: #000;
  }
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-check {
  &:active + .btn-outline-warning,
  &:checked + .btn-outline-warning {
    background-color: $bs-yellow;
    border-color: $bs-yellow;
    color: #000;
  }
}

.btn-outline-warning {
  &.active,
  &.dropdown-toggle.show,
  &:active {
    background-color: $bs-yellow;
    border-color: $bs-yellow;
    color: #000;
  }
}

.btn-check {
  &:active + .btn-outline-warning:focus,
  &:checked + .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
  }
}

.btn-outline-warning {
  &.active:focus,
  &.dropdown-toggle.show:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $bs-yellow;
  }
}

.btn-outline-danger {
  border-color: $bs-red;
  color: $bs-red;

  &:hover {
    background-color: $bs-red;
    border-color: $bs-red;
    color: $bs-white;
  }
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-check {
  &:active + .btn-outline-danger,
  &:checked + .btn-outline-danger {
    background-color: $bs-red;
    border-color: $bs-red;
    color: $bs-white;
  }
}

.btn-outline-danger {
  &.active,
  &.dropdown-toggle.show,
  &:active {
    background-color: $bs-red;
    border-color: $bs-red;
    color: $bs-white;
  }
}

.btn-check {
  &:active + .btn-outline-danger:focus,
  &:checked + .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
  }
}

.btn-outline-danger {
  &.active:focus,
  &.dropdown-toggle.show:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $bs-red;
  }
}

.btn-outline-light {
  border-color: $bs-gray-100;
  color: $bs-gray-100;

  &:hover {
    background-color: $bs-gray-100;
    border-color: $bs-gray-100;
    color: #000;
  }
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check {
  &:active + .btn-outline-light,
  &:checked + .btn-outline-light {
    background-color: $bs-gray-100;
    border-color: $bs-gray-100;
    color: #000;
  }
}

.btn-outline-light {
  &.active,
  &.dropdown-toggle.show,
  &:active {
    background-color: $bs-gray-100;
    border-color: $bs-gray-100;
    color: #000;
  }
}

.btn-check {
  &:active + .btn-outline-light:focus,
  &:checked + .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
  }
}

.btn-outline-light {
  &.active:focus,
  &.dropdown-toggle.show:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $bs-gray-100;
  }
}

.btn-outline-dark {
  border-color: $bs-gray-900;
  color: $bs-gray-900;

  &:hover {
    background-color: $bs-gray-900;
    border-color: $bs-gray-900;
    color: $bs-white;
  }
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-check {
  &:active + .btn-outline-dark,
  &:checked + .btn-outline-dark {
    background-color: $bs-gray-900;
    border-color: $bs-gray-900;
    color: $bs-white;
  }
}

.btn-outline-dark {
  &.active,
  &.dropdown-toggle.show,
  &:active {
    background-color: $bs-gray-900;
    border-color: $bs-gray-900;
    color: $bs-white;
  }
}

.btn-check {
  &:active + .btn-outline-dark:focus,
  &:checked + .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
  }
}

.btn-outline-dark {
  &.active:focus,
  &.dropdown-toggle.show:focus,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $bs-gray-900;
  }
}

.btn-link {
  color: $bs-blue;
  font-weight: $bs-body-font-weight;
  text-decoration: underline;

  &:hover {
    color: $bs-blue-dark;
  }

  &.disabled,
  &:disabled {
    color: $bs-gray;
  }
}

.btn-group-lg > .btn,
.btn-lg {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  padding: $one-rem 1.25rem;
}

.btn-group-sm > .btn,
.btn-sm {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
}
