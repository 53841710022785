.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;

  &.collapse-horizontal {
    height: auto;
    transition: width 0.35s ease;
    width: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
