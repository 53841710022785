.fade {
  transition: opacity 0.15s linear;

  &:not(.show) {
    opacity: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
