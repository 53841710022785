@import './variables';

.alert {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-bottom: $one-rem;
  padding: $one-rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;

  .btn-close {
    padding: 1.25rem $one-rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

.alert-primary {
  background-color: #cfe2ff;
  border-color: #b6d4fe;
  color: #084298;

  .alert-link {
    color: #06357a;
  }
}

.alert-secondary {
  background-color: #e2e3e5;
  border-color: #d3d6d8;
  color: #41464b;

  .alert-link {
    color: #34383c;
  }
}

.alert-success {
  background-color: #d1e7dd;
  border-color: #badbcc;
  color: #0f5132;

  .alert-link {
    color: #0c4128;
  }
}

.alert-info {
  background-color: #cff4fc;
  border-color: #b6effb;
  color: #055160;

  .alert-link {
    color: #04414d;
  }
}

.alert-warning {
  background-color: $bs-white;
  border-color: #ffecb5;
  color: #664d03;

  .alert-link {
    color: #523e02;
  }
}

.alert-danger {
  background-color: #f8d7da;
  border-color: #f5c2c7;
  color: #842029;

  .alert-link {
    color: #6a1a21;
  }
}

.alert-light {
  background-color: #fefefe;
  border-color: #fdfdfe;
  color: #636464;

  .alert-link {
    color: #4f5050;
  }
}

.alert-dark {
  background-color: #d3d3d4;
  border-color: #bcbebf;
  color: #141619;

  .alert-link {
    color: #101214;
  }
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: $one-rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: $one-rem;
  }
}
