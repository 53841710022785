@import './variables';

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: $one-rem;
  padding: 0;
}

.breadcrumb-item {
  + .breadcrumb-item {
    padding-left: 0.5rem;

    &:before {
      color: $bs-gray;
      content: var(--bs-breadcrumb-divider, '/');
      float: left;
      padding-right: 0.5rem;
    }
  }

  &.active {
    color: $bs-gray;
  }
}
