@import './variables';

.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;

  &:after {
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-top: 0.3em solid;
    content: '';
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
  }

  &:empty:after {
    margin-left: 0;
  }
}

.dropdown-menu {
  background-clip: padding-box;
  background-color: $bs-white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: $bs-gray-900;
  display: none;
  font-size: $one-rem;
  list-style: none;
  margin: 0;
  min-width: 10rem;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
  z-index: 1000;

  &[data-bs-popper] {
    left: 0;
    margin-top: 0.125rem;
    top: 100%;
  }
}

.dropdown-menu-start {
  --bs-position: start;

  &[data-bs-popper] {
    left: 0;
    right: auto;
  }
}

.dropdown-menu-end {
  --bs-position: end;

  &[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;

    &[data-bs-popper] {
      left: 0;
      right: auto;
    }
  }

  .dropdown-menu-sm-end {
    --bs-position: end;

    &[data-bs-popper] {
      left: auto;
      right: 0;
    }
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;

    &[data-bs-popper] {
      left: 0;
      right: auto;
    }
  }

  .dropdown-menu-md-end {
    --bs-position: end;

    &[data-bs-popper] {
      left: auto;
      right: 0;
    }
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;

    &[data-bs-popper] {
      left: 0;
      right: auto;
    }
  }

  .dropdown-menu-lg-end {
    --bs-position: end;

    &[data-bs-popper] {
      left: auto;
      right: 0;
    }
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;

    &[data-bs-popper] {
      left: 0;
      right: auto;
    }
  }

  .dropdown-menu-xl-end {
    --bs-position: end;

    &[data-bs-popper] {
      left: auto;
      right: 0;
    }
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;

    &[data-bs-popper] {
      left: 0;
      right: auto;
    }
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;

    &[data-bs-popper] {
      left: auto;
      right: 0;
    }
  }
}

.dropup {
  .dropdown-menu[data-bs-popper] {
    bottom: 100%;
    margin-bottom: 0.125rem;
    margin-top: 0;
    top: auto;
  }

  .dropdown-toggle {
    &:after {
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent;
      border-right: 0.3em solid transparent;
      border-top: 0;
      content: '';
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
    }

    &:empty:after {
      margin-left: 0;
    }
  }
}

.dropend {
  .dropdown-menu[data-bs-popper] {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: 0;
    right: auto;
    top: 0;
  }

  .dropdown-toggle {
    &:after {
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
      border-right: 0;
      border-top: 0.3em solid transparent;
      content: '';
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
    }

    &:empty:after {
      margin-left: 0;
    }

    &:after {
      vertical-align: 0;
    }
  }
}

.dropstart {
  .dropdown-menu[data-bs-popper] {
    left: auto;
    margin-right: 0.125rem;
    margin-top: 0;
    right: 100%;
    top: 0;
  }

  .dropdown-toggle {
    &:after {
      content: '';
      display: inline-block;
      display: none;
      margin-left: 0.255em;
      vertical-align: 0.255em;
    }

    &:before {
      border-bottom: 0.3em solid transparent;
      border-right: 0.3em solid;
      border-top: 0.3em solid transparent;
      content: '';
      display: inline-block;
      margin-right: 0.255em;
      vertical-align: 0.255em;
    }

    &:empty:after {
      margin-left: 0;
    }

    &:before {
      vertical-align: 0;
    }
  }
}

.dropdown-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
}

.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: $bs-gray-900;
  display: block;
  font-weight: $bs-body-font-weight;
  padding: 0.25rem $one-rem;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;

  &:focus,
  &:hover {
    background-color: $bs-gray-200;
    color: #1e2125;
  }

  &.active,
  &:active {
    background-color: $bs-blue;
    color: $bs-white;
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $bs-gray-500;
    pointer-events: none;
  }
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: $bs-gray;
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0;
  padding: 0.5rem $one-rem;
  white-space: nowrap;
}

.dropdown-item-text {
  color: $bs-gray-900;
  display: block;
  padding: 0.25rem $one-rem;
}

.dropdown-menu-dark {
  background-color: $bs-gray-dark;
  border-color: rgba(0, 0, 0, 0.15);
  color: $bs-gray-300;

  .dropdown-item {
    color: $bs-gray-300;

    &:focus,
    &:hover {
      background-color: hsla(0, 0%, 100%, 0.15);
      color: $bs-white;
    }

    &.active,
    &:active {
      background-color: $bs-blue;
      color: $bs-white;
    }

    &.disabled,
    &:disabled {
      color: $bs-gray-500;
    }
  }

  .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15);
  }

  .dropdown-item-text {
    color: $bs-gray-300;
  }

  .dropdown-header {
    color: $bs-gray-500;
  }
}
