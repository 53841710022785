@import './variables';

.popover {
  word-wrap: break-word;
  background-clip: padding-box;
  background-color: $bs-white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  display: block;
  font-family: Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: $bs-body-font-weight;
  left: 0;
  letter-spacing: normal;
  line-break: auto;
  line-height: $bs-body-line-height;
  max-width: 276px;
  position: absolute;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  top: 0;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1070;

  .popover-arrow {
    display: block;
    height: 0.5rem;
    position: absolute;
    width: $one-rem;

    &:after,
    &:before {
      border-color: transparent;
      border-style: solid;
      content: '';
      display: block;
      position: absolute;
    }
  }
}

.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:before {
  border-top-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0.5rem 0;
  bottom: 0;
}

.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow:after,
.bs-popover-top > .popover-arrow:after {
  border-top-color: $bs-white;
  border-width: 0.5rem 0.5rem 0;
  bottom: 1px;
}

.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  height: $one-rem;
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
}

.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:before {
  border-right-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 0;
}

.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow:after,
.bs-popover-end > .popover-arrow:after {
  border-right-color: $bs-white;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 1px;
}

.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-width: 0 0.5rem 0.5rem;
  top: 0;
}

.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow:after,
.bs-popover-bottom > .popover-arrow:after {
  border-bottom-color: $bs-white;
  border-width: 0 0.5rem 0.5rem;
  top: 1px;
}

.bs-popover-auto[data-popper-placement^='bottom'] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  border-bottom: 1px solid #f0f0f0;
  content: '';
  display: block;
  left: 50%;
  margin-left: -0.5rem;
  position: absolute;
  top: 0;
  width: $one-rem;
}

.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  height: $one-rem;
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
}

.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:before {
  border-left-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 0;
}

.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow:after,
.bs-popover-start > .popover-arrow:after {
  border-left-color: $bs-white;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 1px;
}

.popover-header {
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  font-size: $one-rem;
  margin-bottom: 0;
  padding: 0.5rem $one-rem;

  &:empty {
    display: none;
  }
}

.popover-body {
  color: $bs-gray-900;
  padding: $one-rem;
}
