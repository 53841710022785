@import './variables';

.figure {
  display: inline-block;
}

.figure-img {
  line-height: 1;
  margin-bottom: 0.5rem;
}

.figure-caption {
  color: $bs-gray;
  font-size: 0.875em;
}
