@import './variables';

.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1055;
}

.modal-dialog {
  margin: 0.5rem;
  pointer-events: none;
  position: relative;
  width: auto;
}

.modal {
  &.fade .modal-dialog {
    transform: translateY(-50px);
    transition: transform 0.3s ease-out;
  }

  &.show .modal-dialog {
    transform: none;
  }

  &.modal-static .modal-dialog {
    transform: scale(1.02);
  }
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal-dialog-scrollable {
  height: calc(100% - $one-rem);

  .modal-content {
    max-height: 100%;
    overflow: hidden;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - $one-rem);
}

.modal-content {
  background-clip: padding-box;
  background-color: $bs-white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modal-backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1050;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.5;
  }
}

.modal-header {
  align-items: center;
  border-bottom: 1px solid $bs-gray-300;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: $one-rem;

  .btn-close {
    margin: -0.5rem -0.5rem -0.5rem auto;
    padding: 0.5rem;
  }
}

.modal-title {
  line-height: $bs-body-line-height;
  margin-bottom: 0;
}

.modal-body {
  flex: 1 1 auto;
  padding: $one-rem;
  position: relative;
}

.modal-footer {
  align-items: center;
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-top: 1px solid $bs-gray-300;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0.75rem;

  > * {
    margin: 0.25rem;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
    max-width: 500px;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  height: 100%;
  margin: 0;
  max-width: none;
  width: 100vw;

  .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-header {
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;

    .modal-content {
      border: 0;
      border-radius: 0;
      height: 100%;
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border-radius: 0;
    }
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;

    .modal-content {
      border: 0;
      border-radius: 0;
      height: 100%;
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border-radius: 0;
    }
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;

    .modal-content {
      border: 0;
      border-radius: 0;
      height: 100%;
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border-radius: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;

    .modal-content {
      border: 0;
      border-radius: 0;
      height: 100%;
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border-radius: 0;
    }
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;

    .modal-content {
      border: 0;
      border-radius: 0;
      height: 100%;
    }

    .modal-header {
      border-radius: 0;
    }

    .modal-body {
      overflow-y: auto;
    }

    .modal-footer {
      border-radius: 0;
    }
  }
}
