@import './variables';

.tooltip {
  word-wrap: break-word;
  display: block;
  font-family: Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: $bs-body-font-weight;
  letter-spacing: normal;
  line-break: auto;
  line-height: $bs-body-line-height;
  margin: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1080;

  &.show {
    opacity: 0.9;
  }

  .tooltip-arrow {
    display: block;
    height: 0.4rem;
    position: absolute;
    width: 0.8rem;

    &:before {
      border-color: transparent;
      border-style: solid;
      content: '';
      position: absolute;
    }
  }
}

.bs-tooltip-auto[data-popper-placement^='top'],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow:before,
.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #000;
  border-width: 0.4rem 0.4rem 0;
  top: -1px;
}

.bs-tooltip-auto[data-popper-placement^='right'],
.bs-tooltip-end {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  height: 0.8rem;
  left: 0;
  width: 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: #000;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  right: -1px;
}

.bs-tooltip-auto[data-popper-placement^='bottom'],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #000;
  border-width: 0 0.4rem 0.4rem;
  bottom: -1px;
}

.bs-tooltip-auto[data-popper-placement^='left'],
.bs-tooltip-start {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  height: 0.8rem;
  right: 0;
  width: 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow:before,
.bs-tooltip-start .tooltip-arrow:before {
  border-left-color: #000;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  left: -1px;
}

.tooltip-inner {
  background-color: #000;
  border-radius: 0.25rem;
  color: $bs-white;
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
}
