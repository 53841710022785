@import './variables';

.list-group {
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;

  > li:before {
    content: counters(section, '.') '. ';
    counter-increment: section;
  }
}

.list-group-item-action {
  color: $bs-gray-700;
  text-align: inherit;
  width: 100%;

  &:focus,
  &:hover {
    background-color: $bs-gray-100;
    color: $bs-gray-700;
    text-decoration: none;
    z-index: 1;
  }

  &:active {
    background-color: $bs-gray-200;
    color: $bs-gray-900;
  }
}

.list-group-item {
  background-color: $bs-white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: $bs-gray-900;
  display: block;
  padding: 0.5rem $one-rem;
  position: relative;
  text-decoration: none;

  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &.disabled,
  &:disabled {
    background-color: $bs-white;
    color: $bs-gray;
    pointer-events: none;
  }

  &.active {
    background-color: $bs-blue;
    border-color: $bs-blue;
    color: $bs-white;
    z-index: 2;
  }

  + .list-group-item {
    border-top-width: 0;

    &.active {
      border-top-width: 1px;
      margin-top: -1px;
    }
  }
}

.list-group-horizontal {
  flex-direction: row;

  > .list-group-item {
    &:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0.25rem;
    }

    &.active {
      margin-top: 0;
    }

    + .list-group-item {
      border-left-width: 0;
      border-top-width: 1px;

      &.active {
        border-left-width: 1px;
        margin-left: -1px;
      }
    }
  }
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;

    > .list-group-item {
      &:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
      }

      &.active {
        margin-top: 0;
      }

      + .list-group-item {
        border-left-width: 0;
        border-top-width: 1px;

        &.active {
          border-left-width: 1px;
          margin-left: -1px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;

    > .list-group-item {
      &:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
      }

      &.active {
        margin-top: 0;
      }

      + .list-group-item {
        border-left-width: 0;
        border-top-width: 1px;

        &.active {
          border-left-width: 1px;
          margin-left: -1px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;

    > .list-group-item {
      &:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
      }

      &.active {
        margin-top: 0;
      }

      + .list-group-item {
        border-left-width: 0;
        border-top-width: 1px;

        &.active {
          border-left-width: 1px;
          margin-left: -1px;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;

    > .list-group-item {
      &:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
      }

      &.active {
        margin-top: 0;
      }

      + .list-group-item {
        border-left-width: 0;
        border-top-width: 1px;

        &.active {
          border-left-width: 1px;
          margin-left: -1px;
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;

    > .list-group-item {
      &:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
      }

      &.active {
        margin-top: 0;
      }

      + .list-group-item {
        border-left-width: 0;
        border-top-width: 1px;

        &.active {
          border-left-width: 1px;
          margin-left: -1px;
        }
      }
    }
  }
}

.list-group-flush {
  border-radius: 0;

  > .list-group-item {
    border-width: 0 0 1px;

    &:last-child {
      border-bottom-width: 0;
    }
  }
}

.list-group-item-primary {
  background-color: #cfe2ff;
  color: #084298;

  &.list-group-item-action {
    &:focus,
    &:hover {
      background-color: #bacbe6;
      color: #084298;
    }

    &.active {
      background-color: #084298;
      border-color: #084298;
      color: $bs-white;
    }
  }
}

.list-group-item-secondary {
  background-color: #e2e3e5;
  color: #41464b;

  &.list-group-item-action {
    &:focus,
    &:hover {
      background-color: #cbccce;
      color: #41464b;
    }

    &.active {
      background-color: #41464b;
      border-color: #41464b;
      color: $bs-white;
    }
  }
}

.list-group-item-success {
  background-color: #d1e7dd;
  color: #0f5132;

  &.list-group-item-action {
    &:focus,
    &:hover {
      background-color: #bcd0c7;
      color: #0f5132;
    }

    &.active {
      background-color: #0f5132;
      border-color: #0f5132;
      color: $bs-white;
    }
  }
}

.list-group-item-info {
  background-color: #cff4fc;
  color: #055160;

  &.list-group-item-action {
    &:focus,
    &:hover {
      background-color: #badce3;
      color: #055160;
    }

    &.active {
      background-color: #055160;
      border-color: #055160;
      color: $bs-white;
    }
  }
}

.list-group-item-warning {
  background-color: $bs-white;
  color: #664d03;

  &.list-group-item-action {
    &:focus,
    &:hover {
      background-color: #e6dbb9;
      color: #664d03;
    }

    &.active {
      background-color: #664d03;
      border-color: #664d03;
      color: $bs-white;
    }
  }
}

.list-group-item-danger {
  background-color: #f8d7da;
  color: #842029;

  &.list-group-item-action {
    &:focus,
    &:hover {
      background-color: #dfc2c4;
      color: #842029;
    }

    &.active {
      background-color: #842029;
      border-color: #842029;
      color: $bs-white;
    }
  }
}

.list-group-item-light {
  background-color: #fefefe;
  color: #636464;

  &.list-group-item-action {
    &:focus,
    &:hover {
      background-color: #e5e5e5;
      color: #636464;
    }

    &.active {
      background-color: #636464;
      border-color: #636464;
      color: $bs-white;
    }
  }
}

.list-group-item-dark {
  background-color: #d3d3d4;
  color: #141619;

  &.list-group-item-action {
    &:focus,
    &:hover {
      background-color: #bebebf;
      color: #141619;
    }

    &.active {
      background-color: #141619;
      border-color: #141619;
      color: $bs-white;
    }
  }
}
