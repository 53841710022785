.placeholder {
  background-color: currentColor;
  cursor: wait;
  display: inline-block;
  min-height: 1em;
  opacity: 0.5;
  vertical-align: middle;

  &.btn:before {
    content: '';
    display: inline-block;
  }
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@-webkit-keyframes placeholder-wave {
  to {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

@keyframes placeholder-wave {
  to {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
