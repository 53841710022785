@import './variables';

.img-fluid {
  height: auto;
  max-width: 100%;
}

.img-thumbnail {
  height: auto;
  max-width: 100%;
  background-color: $bs-white;
  border: 1px solid $bs-gray-300;
  border-radius: 0.25rem;
  padding: 0.25rem;
}
