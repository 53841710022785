@import './variables';

.card {
  word-wrap: break-word;
  background-clip: border-box;
  background-color: $bs-white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;

  > {
    hr {
      margin-left: 0;
      margin-right: 0;
    }

    .list-group {
      border-bottom: inherit;
      border-top: inherit;

      &:first-child {
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
        border-top-width: 0;
      }

      &:last-child {
        border-bottom-left-radius: calc(0.25rem - 1px);
        border-bottom-right-radius: calc(0.25rem - 1px);
        border-bottom-width: 0;
      }
    }

    .card-header + .list-group,
    .list-group + .card-footer {
      border-top: 0;
    }
  }
}

.card-body {
  flex: 1 1 auto;
  padding: $one-rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: $one-rem;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
  padding: 0.5rem $one-rem;

  &:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
}

.card-footer {
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem $one-rem;

  &:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -0.5rem;
}

.card-header-pills,
.card-header-tabs {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.card-img-overlay {
  border-radius: calc(0.25rem - 1px);
  bottom: 0;
  left: 0;
  padding: $one-rem;
  position: absolute;
  right: 0;
  top: 0;
}

.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;

    > .card {
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        border-left: 0;
        margin-left: 0;
      }

      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;

        .card-header,
        .card-img-top {
          border-top-right-radius: 0;
        }

        .card-footer,
        .card-img-bottom {
          border-bottom-right-radius: 0;
        }
      }

      &:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;

        .card-header,
        .card-img-top {
          border-top-left-radius: 0;
        }

        .card-footer,
        .card-img-bottom {
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
